.index {
  margin: 0px;
  height: 100%;
  background-color: rgb(220, 220, 220);
  font-family: sans-serif;
  text-align: center;
}

.center {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
